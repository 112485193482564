export const PATH = {
  ROOT: "/",
  HOME: "/home",
  PROFILE: "/profile",
  LOGIN: "/login",
  PASSWORD_RESET: "/resetPassword",
  OTP: "/submitOtp",
  NEW_PASSWORD: "/newPassword",
  RESET_SUCCESS: "/resetSuccess",
  ROLE: "/role_selector",

  DASH: "/dashboard",
  Appointments: "/appointments",
  Appointments_SHOWALL: "/appointments/show_all",
  Appointments_HISTORY: "/appointments/history",
  HTM_LIST: "/outlets",
  HTM_VIEW: "/outlet/detail",

  HOS_VIEW_SERVICE_VIEW: "/clients/hospital/services/view",
  HOS_SERVICE_VIEW: "/services/hospital/view",

  PAYMENT_HISTORY_LIST: "/payments",
  PAYMENT_HISTORY_VIEW: "/payment/details",

  CONTACTUS: "/contact_us",
};
export const ToastType = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};
