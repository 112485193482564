import {
  createStyles,
  Header,
  Autocomplete,
  Group,
  Burger,
  Button,
  Image,
  Menu,
  Avatar,
  Text,
  Box,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons";
import {
  IconMessageCircle,
  IconPhoto,
  IconSettings,
  IconSquareCheckFilled,
  IconTrash,
  IconUserCheck,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  search: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));
const links = [
  {
    link: "/",
    label: "home",
  },
  {
    link: "/logout",
    label: "Logout",
  },
];
export default function HeaderSearch(props) {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes } = useStyles();
  const navigate = useNavigate();

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={classes.link}
      onClick={(event) => event.preventDefault()}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={56} className={classes.header}>
      <div className={classes.inner}>
        <Group>
          <Image
            src={process.env.PUBLIC_URL + "/logo192.png"}
            maw={40}
            mx="auto"
            radius="md"
          />
          <Burger
            ml={props.opened ? 180 : "md"}
            opened={props.opened}
            onClick={props.toggle}
            size="sm"
          />
        </Group>

        <Group>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Box>
                <Avatar radius="xl" />
              </Box>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>My Account</Menu.Label>
              <Menu.Item icon={<IconUserCheck size={14} />}>Profile</Menu.Item>
              <Menu.Item icon={<IconSettings size={14} />}>Settings</Menu.Item>
              <Menu.Item icon={<IconSquareCheckFilled size={14} />}>
                Security
              </Menu.Item>

              <Menu.Divider />

              <Menu.Label>Account</Menu.Label>
              <Menu.Item icon={<IconTrash size={14} />}>Logout</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </div>
    </Header>
  );
}
