import React, { Suspense } from "react";
import AuthenticatedGuard from "../guards/authenticatedGuard";
import { Routes, Route, Outlet } from "react-router-dom";
import Loading from "../components/loading";
import Pager from "../pages/Home";
import routeList from "./routesList";
export default function home() {
  console.log(routeList);
  return (
    <Routes>
      {routeList.map((route) => (
        <Route
          exact
          path={route.path}
          key="parent"
          element={route.isProtected ? <AuthenticatedGuard /> : <Outlet />}
        >
          <Route
            exact
            key="child"
            path={route.path}
            element={
              <>
                <Suspense fallback={<Loading />}>
                  <Pager page={route.page} />
                </Suspense>
              </>
            }
          />
        </Route>
      ))}
    </Routes>
  );
}
