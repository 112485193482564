import { Center, Container, Loader } from "@mantine/core";

export default function Loading() {
  return (
    <Container
      fluid={true}
      sx={{
        height: "100vh",
      }}
    >
      <Center sx={{ position: "absolute", top: "50%", left: "50%"  }}>
        <Loader />
      </Center>
    </Container>
  );
}
