import {
  IconPresentationAnalytics,
  IconUser,
  IconCurrencyDollar,
  IconPackages,
  IconDeviceTablet,
  IconCalendarEvent,
} from "@tabler/icons";
import { Icon360View } from "@tabler/icons-react";
import { PATH } from "../../constants/paths";
export default function data(role) {
  switch (role) {
    case "admin":
      return [
        {
          label: "Dashboard",
          icon: IconPresentationAnalytics,
          link: "/dashboard",
        },
        {
          label: "Appointments",
          icon: IconCalendarEvent,
          link: "/appointments",
        },
        { label: "HTM", icon: Icon360View, link: PATH.HTM_LIST },
        {
          label: "Billing",
          icon: IconCurrencyDollar,
          links: [
            { label: "Invoices", link: "/vendor/product/list" },
            { label: "History", link: "/payments" },
          ],
        },
        { label: "Help", icon: IconDeviceTablet, link: "/contact_us" },
        { label: "FAQ's", icon: Icon360View, link: "/outlets" },
        // {
        //   label: "Account",
        //   icon: IconUser,
        //   link: "/account",
        // },
        {
          label: "Settings",
          icon: IconPackages,
          link: "/config",
        },
      ];
  }
}
