import { PATH } from "../constants/paths";
import { lazy } from "react";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Appointment_LIST = lazy(() => import("../pages/Appointment/list"));
const Appointment_SHOW_ALL = lazy(() => import("../pages/Appointment/showAll"));
const Appointment_HISTORY = lazy(() => import("../pages/Appointment/history"));
const HTM_LIST = lazy(() => import("../pages/HTM/list"));
const HTM_VIEW = lazy(() => import("../pages/HTM/view"));

const HOS_SERVICE_VIEW = lazy(() => import("../pages/Services/Hospital/view"));
const HOS_VIEW_SERVICE_VIEW = lazy(() =>
  import("../pages/Service Vendors/Hospital/service-list")
);
const PAYMENT_HISTORY_LIST = lazy(() => import("../pages/Payments/list"));
const PAYMENT_HISTORY_VIEW = lazy(() => import("../pages/Payments/view"));
const CONTACTUS = lazy(() => import("../pages/contactUs"));

const routes = [
  {
    path: PATH.DASH,
    key: PATH.DASH,
    isProtected: true,
    page: <Dashboard />,
  },
  {
    path: PATH.Appointments,
    key: PATH.Appointments,
    isProtected: true,
    page: <Appointment_LIST />,
  },
  {
    path: PATH.Appointments_SHOWALL,
    key: PATH.Appointments_SHOWALL,
    isProtected: true,
    page: <Appointment_SHOW_ALL />,
  },
  {
    path: PATH.Appointments_HISTORY,
    key: PATH.Appointments_HISTORY,
    isProtected: true,
    page: <Appointment_HISTORY />,
  },
  {
    path: PATH.HTM_LIST,
    key: PATH.HTM_LIST,
    isProtected: true,
    page: <HTM_LIST />,
  },
  {
    path: PATH.HTM_VIEW,
    key: PATH.HTM_VIEW,
    isProtected: true,
    page: <HTM_VIEW />,
  },
  {
    path: PATH.HOS_SERVICE_VIEW,
    key: PATH.HOS_SERVICE_VIEW,
    isProtected: true,
    page: <HOS_SERVICE_VIEW />,
  },
  {
    path: PATH.HOS_VIEW_SERVICE_VIEW,
    key: PATH.HOS_VIEW_SERVICE_VIEW,
    isProtected: true,
    page: <HOS_VIEW_SERVICE_VIEW />,
  },
  {
    path: PATH.PAYMENT_HISTORY_LIST,
    key: PATH.PAYMENT_HISTORY_LIST,
    isProtected: true,
    page: <PAYMENT_HISTORY_LIST />,
  },
  {
    path: PATH.PAYMENT_HISTORY_VIEW,
    key: PATH.PAYMENT_HISTORY_VIEW,
    isProtected: true,
    page: <PAYMENT_HISTORY_VIEW />,
  },
  {
    path: PATH.CONTACTUS,
    key: PATH.CONTACTUS,
    isProtected: true,
    page: <CONTACTUS />,
  },
];
export default routes;
