import React, { lazy, Suspense } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { PATH } from "../constants/paths";
import Loading from "../components/loading";
const Login = lazy(() => import("../pages/Login/login"));
const ForgetPassword = lazy(() => import("../pages/Login/forgetPassword"));

export default function LoginRoutes() {
  return (
    <Switch>
      <Route exact path={PATH.ROOT} element={<Login />} />
      <Route
        exact
        path={PATH.LOGIN}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          </>
        }
      />
      <Route
        exact
        path={PATH.PASSWORD_RESET}
        element={
          <>
            <Suspense fallback={<Loading />}>
              <ForgetPassword />
            </Suspense>
          </>
        }
      />
    </Switch>
  );
}
