import {
  Navbar,
  Group,
  Code,
  ScrollArea,
  createStyles,
  Text,
  Title,
} from "@mantine/core";

import UserButton from "./UserButton";
import LinksGroup from "./GroupLinks";

import data from "./links";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    backgroundColor: "",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function NavbarNested(props) {
  const { classes } = useStyles();
  let links_data = data(localStorage.getItem("user_role") || "admin");
  if (!links_data) {
    links_data = [];
  }
  const links = links_data.map((item) => (
    <LinksGroup {...item} key={item.label} />
  ));

  return (
    <Navbar
      style={{ display: !props.opened ? "none" : "flex" }}
      width={{ sm: !props.opened ? 0 : 250 }}
      sx={{ backgroundColor: "#f8f8fa" }}
      p={"md"}
    >
      <Title order={5} ml={"lg"} mb="lg">
        Menu
      </Title>
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>
    </Navbar>
  );
}
